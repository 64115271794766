import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, TextField, Select, MenuItem, FormControl, InputLabel, Container, Snackbar, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import autopenIcon from './images/autopen-icon.png';

function StartTest() {
    const navigate = useNavigate();
    const [targetURL, setTargetURL] = useState('');
    const [scannerIp, setScannerIp] = useState('');
    const [scanType, setScanType] = useState('Lightweight');
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const apiUrl = 'http://localhost:8000/api/run-scan/';
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ targetURL, scannerIp, scanType })
            });
            setLoading(false);
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            setSnackbar({ open: true, message: 'Scan created successfully: ' + data.message });
            if (window.confirm('Would you like to review the results?')) {
                navigate('/results');
            } else {
                navigate('/dashboard');
            }
        } catch (error) {
            setSnackbar({ open: true, message: 'Error creating scan: ' + error.message });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            <AppBar position="static" color="default" elevation={1}>
                <Toolbar>
                    <img src={autopenIcon} alt="AutoPen Logo" style={{ marginRight: '20px', width: 50 }} />
                    <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setIsOpen(!isOpen)}>
                        <MenuIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={() => navigate('/login')}>
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {isOpen && (
                <Box sx={{ padding: 2, backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 64, zIndex: 1200 }}>
                    <Button onClick={() => navigate('/')} color="inherit">Home</Button>
                    <Button onClick={() => navigate('/profile')} color="inherit">My Profile</Button>
                    <Button onClick={() => navigate('/dashboard')} color="inherit">Dashboard</Button>
                    <Button onClick={() => navigate('/settings')} color="inherit">Settings</Button>
                    <Button onClick={() => navigate('/reports')} color="inherit">View Reports</Button>
                    <Button onClick={() => navigate('/help')} color="inherit">Help Center</Button>
                </Box>
            )}

            <Container component="main" maxWidth="sm">
                <Box sx={{ mt: 3, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5">Launch Scan</Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                        <TextField label="Target URL" fullWidth margin="normal" value={targetURL} onChange={e => setTargetURL(e.target.value)} required />
                        <TextField label="Scanner IP" fullWidth margin="normal" value={scannerIp} onChange={e => setScannerIp(e.target.value)} required />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Scan Type</InputLabel>
                            <Select value={scanType} label="Scan Type" onChange={e => setScanType(e.target.value)} required>
                                <MenuItem value="Lightweight">Lightweight</MenuItem>
                                <MenuItem value="Fast">Fast</MenuItem>
                                <MenuItem value="Balanced">Balanced</MenuItem>
                                <MenuItem value="Deep">Deep</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading} sx={{ mt: 3, mb: 2 }}>
                            {loading ? <CircularProgress size={24} /> : 'Start Scan'}
                        </Button>
                    </Box>
                </Box>
            </Container>

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} message={snackbar.message} />
        </>
    );
}

export default StartTest;