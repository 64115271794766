import React, { useEffect, useState } from 'react';
import { Container, Typography, AppBar, Toolbar, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function TestResults() {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]); // State for the first table data
    const [detailedRows, setDetailedRows] = useState(null); // State for the detailed table data
    const [showFullReport, setShowFullReport] = useState(false); // State to toggle view
    const [selectedRow, setSelectedRow] = useState(null); // State to hold the selected row data
    const apiUrl = 'http://localhost:8000/api/scan-results/';

    useEffect(() => {
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setRows(data); // Assuming the API returns an array of objects directly
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);

    useEffect(() => {
        if (selectedRow) {
            // Append the 'id' parameter to the fetch URL
            const url = `${apiUrl}?id=${selectedRow.id}`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    setDetailedRows(data); // Adjust based on your data structure
                    setShowFullReport(true);
                })
                .catch(error => {
                    console.error('Error fetching detailed report:', error);
                });
        }
    }, [selectedRow]);
    function openFullReport(row) {
        setSelectedRow(row);
    }

    const styles = {
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        th: {
            border: '1px solid black',
            padding: '8px',
            textAlign: 'left',
            backgroundColor: '#f2f2f2',
        },
        td: {
            border: '1px solid black',
            padding: '8px',
            textAlign: 'left',
        },
        tr: {
            cursor: 'pointer',
        },
    };

    return (
        <>
            <AppBar position="static" color="default" elevation={1}>
                <Toolbar>
                    <Button color="inherit" onClick={() => navigate('/Dashboard')}>User Dashboard</Button>
                    <Button color="inherit" onClick={() => navigate('/Profile')}>My Profile</Button>
                    <Box sx={{ flexGrow: 1 }} />
                </Toolbar>
            </AppBar>
            <Container>
                {!showFullReport ? (
                    <>
                        <Typography variant="h4" gutterBottom>Scan Results</Typography>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.th}>ID</th>
                                    <th style={styles.th}>Date</th>
                                    <th style={styles.th}>Time</th>
                                    <th style={styles.th}>Depth</th>
                                    <th style={styles.th}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map(row => (
                                    <tr key={row.id} style={styles.tr} onClick={() => openFullReport(row)}>
                                        <td style={styles.td}>{row.id}</td>
                                        <td style={styles.td}>{row.date}</td>
                                        <td style={styles.td}>{row.time}</td>
                                        <td style={styles.td}>{row.depth}</td>
                                        <td style={styles.td}>{row.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <>
                        <Typography variant="h4" gutterBottom>Detailed Findings for ID: {selectedRow && selectedRow.id}</Typography>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.th}>Name</th>
                                    <th style={styles.th}>Severity</th>
                                    <th style={styles.th}>Issue</th>
                                    <th style={styles.th}>Remediation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detailedRows.map((detail, index) => (
                                    <tr key={index} style={styles.tr}>
                                        <td style={styles.td}>{detail.name}</td>
                                        <td style={styles.td}>{detail.severity}</td>
                                        <td style={styles.td}>{detail.issue}</td>
                                        <td style={styles.td}>{detail.remediation}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Button onClick={() => setShowFullReport(false)}>Back to Results</Button>
                    </>
                )}
            </Container>
        </>
    );
}

export default TestResults;
