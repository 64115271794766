import React, { useState } from 'react';
import { AppBar, Box, Button, Container, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Importing MenuIcon from Material-UI icons
import { useNavigate } from 'react-router-dom';
import autoPenIcon from './images/autopen-icon.png';  // Corrected path
import securityIcon from './images/EnhancedSecurityIcon.png';  // Corrected path
import speedIcon from './images/SpeedIcon.png';  // Corrected path
import adaptIcon from './images/adaptIcon.png';  // Corrected path

const HomePage = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false); // State to manage the dropdown menu

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };

    const handleNavigation = (path) => {
      navigate(path);
    };

    return (
      <>
        <AppBar position="static" color="default" elevation={1}>
          <Toolbar>
            <img src={autoPenIcon} alt="AutoPen Logo" style={{ width: 50 }} />
            <Box sx={{ flexGrow: 1 }} />
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleToggle}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {isOpen && (
          <Box sx={{ padding: 2, backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 64 }}>
            <Button onClick={() => handleNavigation('/login')} color="inherit">Login</Button>
            <Button onClick={() => handleNavigation('/register')} color="inherit">Register</Button>
            <Button onClick={() => handleNavigation('/about')} color="inherit">About Us</Button>
            <Button onClick={() => handleNavigation('/contact')} color="inherit">Contact Us</Button>
            <Button onClick={() => handleNavigation('/FAQ')} color="inherit">FAQ</Button>
            <Button onClick={() => handleNavigation('/pricing')} color="inherit">Pricing</Button>
          </Box>
        )}

        <Box component="section" id="features" sx={{ py: 6 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Typography variant="h2" gutterBottom>
              Key Features
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', my: 4 }}>
              <div>
                <img src={securityIcon} alt="Security Icon" width="200" height="200" />
                <Typography variant="h6">Enhanced Security</Typography>
                <Typography variant="body2">Identify and rectify vulnerabilities in your networks and systems.</Typography>
              </div>
              <div>
                <img src={speedIcon} alt="Speed Icon" width="200" height="200" />
                <Typography variant="h6">Quick and Efficient</Typography>
                <Typography variant="body2">Rapidly scan and analyze large volumes of data for faster results.</Typography>
              </div>
              <div>
                <img src={adaptIcon} alt="Adapt Icon" width="200" height="200" />
                <Typography variant="h6">Adaptive Testing</Typography>
                <Typography variant="body2">Stay ahead of evolving threats with machine learning.</Typography>
              </div>
            </Box>
            <Button variant="contained" color="primary" href="#features">
              Learn More
            </Button>
          </Container>
        </Box>

        <Box component="section" id="about" sx={{ backgroundColor: '#f5f5f5', py: 6 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body1">
              AutoPen is a leading provider of AI-powered penetration testing solutions, dedicated to helping businesses secure their digital assets.
            </Typography>
          </Container>
        </Box>

        <Box component="section" id="contact" sx={{ py: 6 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions or inquiries, please feel free to contact our team.
            </Typography>
            <Button variant="contained" color="primary" href="./Contact">
              Contact Us
            </Button>
          </Container>
        </Box>

        <Box component="footer" sx={{ backgroundColor: '#333', color: 'white', py: 2 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Typography variant="body2">
              &copy; 2023 AutoPen. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </>
    );
};

export default HomePage;