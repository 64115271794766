import { Container, Typography } from '@mui/material';

function Profile({ user }) {
  return (
    <Container>
      <Typography variant="h4">User Profile</Typography>
      <Typography variant="h6">Name: {user.name}</Typography>
      <Typography variant="h6">Email: {user.email}</Typography>
    </Container>
  );
}

// This function gets called on every request
export async function getServerSideProps(context) {
  const { req, res } = context;
  const response = await fetch('http://localhost:3000/api/user/profile-id', {
    headers: {
      'Authorization': `Bearer ${req.cookies.token}`,  // Adjust based on how you manage tokens
    },
  });
  const user = await response.json();

  // Pass data to the page via props
  return { props: { user } }
}

export default Profile;