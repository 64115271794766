import React, { useState } from 'react';
import { Container, Typography, AppBar, Toolbar, IconButton, Button, Box, TextField, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import autopenIcon from './images/autopen-icon.png';

function Contact() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleNavigation = (path) => {
        setIsOpen(false);
        navigate(path);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email || !message) {
            setOpenSnackbar(true); // Opens a snackbar if validation fails
            return;
        }

        // Post data to the backend
        fetch('https://your-backend-url.com/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, message }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            setEmail('');
            setMessage('');
            alert('Thank you for your message. We will get back to you soon.');
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('There was an issue sending your message. Please try again later.');
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <>
            <AppBar position="static" color="default" elevation={1}>
                <Toolbar>
                    <img src={autopenIcon} alt="AutoPen Logo" style={{ width: 50 }} />
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleToggle}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {isOpen && (
                <Box sx={{ padding: 2, backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 64 }}>
                    <Button onClick={() => handleNavigation('/')} color="inherit">Home</Button>
                    <Button onClick={() => handleNavigation('/login')} color="inherit">Login</Button>
                    <Button onClick={() => handleNavigation('/register')} color="inherit">Register</Button>
                    <Button onClick={() => handleNavigation('/about')} color="inherit">About Us</Button>
                    <Button onClick={() => handleNavigation('/contact')} color="inherit">Contact Us</Button>
                    <Button onClick={() => handleNavigation('/faq')} color="inherit">FAQ</Button>
                    <Button onClick={() => handleNavigation('/pricing')} color="inherit">Pricing</Button>
                </Box>
            )}

            <Container maxWidth="md">
                <Typography variant="h4" gutterBottom>Contact Us</Typography>
                <Box sx={{ ml: 8 }}>
                    <Typography variant="body1" gutterBottom>
                        If you would like to reach out for more information, do not hesitate to contact us. Our business hours are 9-5 during the week, and we will respond within 3-5 business days.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Email us at <a href="mailto:support@autopentest.net">support@autopentest.net</a> Or create a message below
                    </Typography>
                    
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Your Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            label="Your Message"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Button type="submit" variant="contained" sx={{ mt: 2, backgroundColor: '#800080', '&:hover': { backgroundColor: '#5e005e' } }}>
                            Send Message
                        </Button>
                    </form>
                </Box>
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error">
                    Please fill in all fields.
                </Alert>
            </Snackbar>
        </>
    );
}

export default Contact;