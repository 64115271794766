import React from 'react';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import About from './pages/About';
import Profile from './pages/Profile';
import Test from './pages/Test';
import Results from './pages/Results';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Pricing from './pages/Pricing';

const routes = [
  { path: '/', element: <Home /> },
  { path: '/Login', element: <Login /> },
  { path: '/Register', element: <Register /> },
  { path: '/Dashboard', element: <Dashboard /> },
  { path: '/About', element: <About /> },
  { path: '/Profile', element: <Profile /> },
  { path: '/Test', element: <Test /> },
  { path: '/Results', element: <Results /> },
  { path: '/Contact', element: <Contact /> },
  { path: '/FAQ', element: <FAQ /> },
  { path: '/Pricing', element: <Pricing /> },
  
  
];

export default routes;