import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Button, Box, AppBar, Toolbar, IconButton, Paper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import autoPenIcon from './images/autopen-icon.png'; // Adjust the path as needed
import './pageStyles/Login.css'; // Import the CSS file for styling

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username.trim() === '' || password.trim() === '') {
      setError('Please enter both username and password.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/login', {
        username,
        password
      });
      if (response.status === 200) {
        navigate('/dashboard'); // Navigate to dashboard on success
      } else {
        setError('Invalid username or password.');
      }
    } catch (err) {
      setError('Login failed, please try again.');
    }
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <img src={autoPenIcon} alt="AutoPen Logo" style={{ width: 50 }} />
          <Box sx={{ flexGrow: 1 }} />
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleToggle}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {isOpen && (
        <Box sx={{ padding: 2, backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 64 }}>
          <Button onClick={() => handleNavigation('/')} color="inherit">Home</Button>
          <Button onClick={() => handleNavigation('/login')} color="inherit">Login</Button>
          <Button onClick={() => handleNavigation('/register')} color="inherit">Register</Button>
          <Button onClick={() => handleNavigation('/about')} color="inherit">About Us</Button>
          <Button onClick={() => handleNavigation('/contact')} color="inherit">Contact Us</Button>
          <Button onClick={() => handleNavigation('/faq')} color="inherit">FAQ</Button>
          <Button onClick={() => handleNavigation('/pricing')} color="inherit">Pricing</Button>
        </Box>
      )}

      <Container component="main" maxWidth="xs">
        <Paper elevation={6} sx={{ my: 4, p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 3 }}>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <div className="error">{error}</div>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#800080' }} // Purple color
            >
              Login
            </Button>
            <Typography sx={{ mt: 2, textAlign: 'center' }}>
              Don't have an account?
              <Button onClick={() => navigate('/register')} sx={{ color: '#faf8fa', backgroundColor: '#800080', boxShadow: 'none', ml: 1 }}>Register Here</Button>
            </Typography>
          </form>
        </Paper>
      </Container>
    </>
  );
}

export default Login;