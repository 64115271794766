import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import autopenIcon from './images/autopen-icon.png'; // Adjust the path as needed
import './pageStyles/Dashboard.css'; // Ensure CSS is linked

function Dashboard() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // Sample data for Quick Stats
  const stats = {
    tasksCompleted: 120,
    openTasks: 15,
    messages: 5
  };

  // Personalized greeting based on time of day
  const hours = new Date().getHours();
  const greet = hours < 12 ? 'Good morning' : hours < 18 ? 'Good afternoon' : 'Good evening';

  return (
    <>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <img src={autopenIcon} alt="AutoPen Logo" style={{ width: 50 }} />
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            
          </Typography>
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setIsOpen(!isOpen)}>
            <MenuIcon />
          </IconButton>
          <Button color="inherit" onClick={() => navigate('/login')}>Logout</Button>
        </Toolbar>
      </AppBar>

      {isOpen && (
        <Box sx={{ padding: 2, backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 64 }}>
          <Button onClick={() => navigate('/')} color="inherit">Home</Button>
          <Button onClick={() => navigate('/profile')} color="inherit">My Profile</Button>
          <Button onClick={() => navigate('/test')} color="inherit">Start a Test</Button>
          <Button onClick={() => navigate('/settings')} color="inherit">Settings</Button>
          <Button onClick={() => navigate('/reports')} color="inherit">View Reports</Button>
          
        </Box>
      )}

      <div className="user-dashboard-container" style={{ padding: '20px' }}>
        <h2>{greet}, here's your Dashboard</h2>
        <div style={{ backgroundColor: '#800080', padding: '20px', color: 'white', textAlign: 'center' }}>
          <h3>Quick Stats</h3>
          <p>Tasks Completed: {stats.tasksCompleted}</p>
          <p>Open Tasks: {stats.openTasks}</p>
          <p>New Messages: {stats.messages}</p>
          <Link to="/profile" style={{ backgroundColor: 'white', color: 'black', padding: '10px 20px', margin: '10px', display: 'inline-block', textDecoration: 'none', borderRadius: '5px' }}>My Profile</Link>
          <Link to="/test" style={{ backgroundColor: 'white', color: 'black', padding: '10px 20px', margin: '10px', display: 'inline-block', textDecoration: 'none', borderRadius: '5px' }}>Start a Test</Link>
          <Link to="/settings" style={{ backgroundColor: 'white', color: 'black', padding: '10px 20px', margin: '10px', display: 'inline-block', textDecoration: 'none', borderRadius: '5px' }}>Settings</Link>
          <Link to="/reports" style={{ backgroundColor: 'white', color: 'black', padding: '10px 20px', margin: '10px', display: 'inline-block', textDecoration: 'none', borderRadius: '5px' }}>View Reports</Link>
          <Link to="/help" style={{ backgroundColor: 'white', color: 'black', padding: '10px 20px', margin: '10px', display: 'inline-block', textDecoration: 'none', borderRadius: '5px' }}>Help Center</Link>
        </div>
      </div>
    </>
  );
}

export default Dashboard;