import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import the Menu icon for use in IconButton
import { useNavigate } from 'react-router-dom';
import './pageStyles/Pricing.css'; 
import autoPenIcon from './images/autopen-icon.png'; // Corrected path for the image

function PricingPlans() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // State to manage the dropdown menu

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <img src={autoPenIcon} alt="AutoPen Logo" style={{ width: 50 }} />
          <Box sx={{ flexGrow: 1 }} />
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleToggle}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {isOpen && (
        <Box sx={{ padding: 2, backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 64 }}>
          <Button onClick={() => handleNavigation('/login')} color="inherit">Login</Button>
          <Button onClick={() => handleNavigation('/register')} color="inherit">Register</Button>
          <Button onClick={() => handleNavigation('/about')} color="inherit">About Us</Button>
          <Button onClick={() => handleNavigation('/contact')} color="inherit">Contact Us</Button>
          <Button onClick={() => handleNavigation('/FAQ')} color="inherit">FAQ</Button>
          <Button onClick={() => handleNavigation('/pricing')} color="inherit">Pricing</Button>
        </Box>
      )}

      <div className="pricing-container">
        <h1>Our Pricing Plans</h1>
        <div className="plans">
          <div className="plan">
            <h2>Free</h2>
            <p>2 scans per week</p>
            <p>$0/mo</p>
          </div>
          <div className="plan">
            <h2>Plus</h2>
            <p>3 scans per day</p>
            <p>Extra scans at $19.99/scan</p>
            <p>$29.99/mo</p>
          </div>
          <div className="plan">
            <h2>Premium</h2>
            <p>Unlimited scans per day*</p>
            <p>32 scans per week limit</p>
            <p>Reset week limit for $59.99</p>
            <p>$99.99/mo</p>
          </div>
        </div>
        <p className="note">*Weekly limit applies</p>
      </div>
    </>
  );
}

export default PricingPlans;