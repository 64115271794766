import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Button, Box, AppBar, Toolbar, IconButton, Paper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import autoPenIcon from './images/autopen-icon.png';  // Adjust the path as needed

function RegisterPage() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    emailConfirm: '',
    password: '',
    passwordConfirm: ''
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.email !== formData.emailConfirm) {
      alert('Emails do not match');
      return;
    }
    if (formData.password !== formData.passwordConfirm) {
      alert('Passwords do not match');
      return;
    }
  
    try {
      const response = await fetch('http://localhost:5000/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: formData.username,
          email: formData.email,
          password: formData.password
        })
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        navigate('/login'); // Redirect to login page after successful registration
      } else {
        const error = await response.json();
        throw new Error(error.message || 'Failed to register');
      }
    } catch (error) {
      console.error('Registration failed:', error);
      alert(error.message);
    }
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <img src={autoPenIcon} alt="AutoPen Logo" style={{ width: 50 }} />
          <Box sx={{ flexGrow: 1 }} />
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleToggle}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {isOpen && (
        <Box sx={{ padding: 2, backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column' }}>
        <Button onClick={() => handleNavigation('/login')} color="inherit">Login</Button>
        <Button onClick={() => handleNavigation('/register')} color="inherit">Register</Button>
        <Button onClick={() => handleNavigation('/about')} color="inherit">About Us</Button>
        <Button onClick={() => handleNavigation('/contact')} color="inherit">Contact Us</Button>
        <Button onClick={() => handleNavigation('/faq')} color="inherit">FAQ</Button>
        <Button onClick={() => handleNavigation('/pricing')} color="inherit">Pricing</Button>
      </Box>
      )}

      <Container component="main" maxWidth="xs">
        <Paper elevation={6} sx={{ mt: 8, padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <form onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={formData.username}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailConfirm"
              label="Confirm Email Address"
              name="emailConfirm"
              autoComplete="email"
              value={formData.emailConfirm}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={formData.password}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordConfirm"
              label="Confirm Password"
              type="password"
              id="passwordConfirm"
              autoComplete="new-password"
              value={formData.passwordConfirm}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#800080', '&:hover': { backgroundColor: '#540054' } }} // Set the button color to purple
            >
              Register
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}

export default RegisterPage;